import React, { Component } from "react"
// import { TweenMax } from "gsap/all"
// import SplitText from "gsap/SplitText"
// import ScrollMagic from "scrollmagic";
import nextId from "react-id-generator";

class HeroTitle extends Component {
  constructor(props) {
    super(props);
    // this.myElement = null;
    // this.myTween = null;
    // this.heroID = nextId("hero-");
  }
  
  
  componentDidMount() {
    // var heroID = this.heroID;
    // var controller = new ScrollMagic.Controller();
    // var id = "#" + heroID;
    // const Split = new SplitText(id, {
    //   type: "chars"
    // });

    // new ScrollMagic.Scene({
    //   triggerElement: id,
    //   duration: 400, // scroll distance
    //   offset: -200 // start this scene after scrolling for 50px
    // })
    // .on("start", function (e) {
    //   TweenMax.staggerFrom(
    //     Split.chars,
    //     1,
    //     {
    //       ease: 'Power2.easeOut',
    //       yPercent: 100,
    //       opacity: 0
    //     },
    //     0.1
    //   );
    // })
    // .addTo(controller);
  }

  render() {
    return <div id={this.heroID} className="hero-title">Borghetto</div>;
  }

}

export default HeroTitle;