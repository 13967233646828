import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./img/logo"

const Header = ({ siteTitle }) => (
  <header className="navbar">
    <div className="container">
      <div className="nav-logo">
        <Link to="/">
          <Logo></Logo>
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Azienda Agricola Borghetto`,
}

export default Header
